import { DragHandle } from '@mui/icons-material'
import { TableBody, TableRow, TableCell } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export const getItemStyle = ({ snapshot, draggableStyle, background }) => {
  return {
    ...draggableStyle,
    background,
    display: snapshot.isDragging ? 'table' : '',
    left: 'auto !important',
    top: draggableStyle.top,
    boxShadow: snapshot.isDragging ? '0 5px 18px 5px rgba(0, 0, 0, 0.25)' : ''
  }
}

export const DroppableComponent =
  ({ onDragEnd, additionalRow }) =>
  ({ children }) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'droppable-list-array'} direction="vertical">
          {(provided, snapshot) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  backgroundColor: snapshot.isDraggingOver
                    ? 'lightgrey'
                    : '#fff'
                }}
              >
                {children}
                {provided.placeholder}
                {additionalRow}
              </TableBody>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }

export const DraggableComponent =
  ({
    draggableId,
    index,
    className,
    background = '#fff',
    dragHandleVisible = true,
    dragHandleDisabled = false,
    style = {},
    onRowClick
  }) =>
  ({ children }) => {
    return (
      <Draggable
        draggableId={draggableId}
        index={index}
        isDragDisabled={dragHandleDisabled}
      >
        {(provided, snapshot) => {
          return (
            <TableRow
              ref={provided.innerRef}
              snapshot={snapshot}
              {...provided.draggableProps}
              style={{
                ...getItemStyle({
                  snapshot,
                  draggableStyle: provided.draggableProps.style,
                  ...(!className && { background })
                }),
                ...style
              }}
              className={className}
              hover={false}
              onClick={onRowClick}
            >
              <TableCell {...provided.dragHandleProps} style={{ width: 0 }}>
                {dragHandleVisible && (
                  <DragHandle
                    color={dragHandleDisabled ? 'disabled' : 'primary'}
                  />
                )}
              </TableCell>

              {children}
            </TableRow>
          )
        }}
      </Draggable>
    )
  }

export const onDragEnd = async ({
  result,
  items,
  setItems,
  patchData = () => null,
  mutateData = () => null,
  setDisableDrag = () => null
}) => {
  if (!result.destination) return

  setDisableDrag(true)
  const backupList = [...items]
  const listCopy = [...items]

  const newOrderId = listCopy[result.destination.index].order
  const oldOrderId = listCopy[result.source.index].order

  const [removedElement] = listCopy.splice(result.source.index, 1)
  listCopy.splice(result.destination.index, 0, removedElement)

  setItems(listCopy)

  const response = await patchData(result.draggableId, {
    order: newOrderId,
    position: newOrderId > oldOrderId ? 'below' : 'above'
  })
  if (!response || (response?.status !== 200 && response?.status !== 201)) {
    setItems(backupList)
    setDisableDrag(false)
    return
  }

  mutateData && (await mutateData())
  setDisableDrag(false)
}
