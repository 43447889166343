import { Grid, Typography, Box, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useStyles from '@components/common/WrapperBlock/styles'

const WrapperBlock = ({
  children,
  title,
  description = '',
  status,
  extraHeaderBlock,
  extraHeaderBlockTabs,
  preFilters,
  filters,
  alternativeChildren = false,
  data_testid = '',
  height = 'auto',
  padding = 3,
  visible = false
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const styleWithExtraAndNoDescription = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
  const currentURL = window.location.href

  return (
    <Grid
      container
      className={currentURL.includes('/projects/summary') ? null : classes.root}
      sx={{
        height,
        background: currentURL.includes('/projects/summary')
          ? theme.palette.elements.white
          : null,
        borderTopLeftRadius: theme.spacing(3),
        borderBottomLeftRadius: theme.spacing(3)
      }}
      padding={padding}
      data-testid={data_testid}
    >
      {(title || extraHeaderBlock || extraHeaderBlockTabs || description) && (
        <Grid
          item
          xs={12}
          className={
            currentURL?.includes('/cashflow') ? null : classes.informations
          }
          sx={
            !description?.length && extraHeaderBlock
              ? { ...styleWithExtraAndNoDescription }
              : title && description
              ? { mb: currentURL?.includes('invoicing/cashflow/') ? 3 : null }
              : {}
          }
        >
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent:
                title || description ? 'space-between' : 'flex-end',
              flexWrap: 'nowrap'
            }}
          >
            {(title || description) && (
              <div>
                {status && title ? (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      data-testid="Typography-77307b60-8f9b-4735-a939-0b202bc7221a"
                    >
                      {title}
                    </Typography>
                    <Box ml={3}>{status}</Box>
                  </Box>
                ) : title ? (
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    data-testid="Typography-a9e12d7d-9cd7-41bb-9ad8-a17c3f55277a"
                  >
                    {title}
                  </Typography>
                ) : null}

                {description && (
                  <Typography
                    variant="body2"
                    color="text.primary"
                    data-testid="Typography-a4825035-92c6-4471-9e58-af75caadf814"
                  >
                    {description}
                  </Typography>
                )}
              </div>
            )}

            {extraHeaderBlock && <Box sx={{ ml: 1 }}>{extraHeaderBlock}</Box>}
          </Grid>
          {extraHeaderBlockTabs && (
            <Grid item xs={12} mt={2}>
              {extraHeaderBlockTabs}
            </Grid>
          )}
        </Grid>
      )}

      {(preFilters || filters) && (
        <Grid
          item
          xs={12}
          className={classes.filters}
          display="flex"
          justifyContent="flex-end"
          sx={
            preFilters
              ? { py: '0 !important', overflowX: 'auto', overflowY: 'hidden' }
              : { overflowX: 'auto', overflowY: 'hidden' }
          }
        >
          {preFilters}
          {preFilters && <Divider orientation="vertical" />}
          {preFilters ? <Box ml={1}>{filters}</Box> : filters}
        </Grid>
      )}

      <Grid
        item
        xs={12}
        className={
          alternativeChildren
            ? classes.alternative
            : children && visible
            ? classes.childrenVisible
            : children
            ? classes.children
            : classes.noChildren
        }
      >
        {children}
      </Grid>
    </Grid>
  )
}

export default WrapperBlock
