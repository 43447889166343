const DEFAULT_BACKEND_DATE_FORMAT = 'dd-MM-yyyy'
const DEFAULT_BACKEND_DATE_FORMAT_REVERSE = 'yyyy-MM-dd'

export default function unformatDate(
  time,
  format = DEFAULT_BACKEND_DATE_FORMAT
) {
  if (time && typeof time === 'string') {
    let splitTime = time.split(' ')[1]
    if (splitTime) splitTime = splitTime.split(':')
    const pattern = /-/.test(time) ? '-' : '/'
    const splitDate = time.slice(0, 10).split(pattern)

    const t = {
      year: 0,
      month: 0,
      day: 0
    }

    if (format === DEFAULT_BACKEND_DATE_FORMAT) {
      t.year = splitDate[2]
      t.month = splitDate[1] - 1
      t.day = splitDate[0]
    }

    if (format === DEFAULT_BACKEND_DATE_FORMAT_REVERSE) {
      t.year = splitDate[0]
      t.month = splitDate[1] - 1
      t.day = splitDate[2]
    }

    const hours = splitTime && splitTime[0] ? splitTime[0] : 0
    const minutes = splitTime && splitTime[1] ? splitTime[1] : 0
    const seconds = splitTime && splitTime[2] ? splitTime[2] : 0

    return new Date(t.year, t.month, t.day, hours, minutes, seconds)
  }
  return new Date()
}
