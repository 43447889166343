// import { useEffect } from 'react'
import { useTranslation } from '@contexts/translation'
import { TablePagination, TableRow } from '@mui/material'

const CommonPagination = ({
  setQueries = () => null,
  dispatchQueries = () => null,
  count = 0,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  const { dictionary } = useTranslation()

  const handleChangePage = (event, newPage) => {
    setPage && setPage(newPage)
    setQueries && setQueries(prev => ({ ...prev, page: newPage + 1 }))
    dispatchQueries && dispatchQueries({ page: newPage + 1 })
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage && setRowsPerPage(+event.target.value)
    setQueries &&
      setQueries(prev => ({
        ...prev,
        // page_size: event.target.value == '-1' ? 99999 : event.target.value,
        page_size: event.target.value,
        page: 1
      }))
    dispatchQueries &&
      dispatchQueries({ page_size: event.target.value, page: 1 })
    setPage && setPage(0)
  }
  return (
    <TableRow hover={false}>
      <TablePagination
        labelRowsPerPage={dictionary.rows_per_page}
        // rowsPerPageOptions={[5, 10, 25, 50, 100, { label: 'All', value: -1 }]}
        rowsPerPageOptions={[5, 10, 25, 50, 100, { label: 'All', value: 9999 }]}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page'
          },
          native: true
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableRow>
  )
}

export default CommonPagination
