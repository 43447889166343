import { isValidTimeValue } from './isValidTimeValue'
import unformatDate from './unformatDate'

export const sortString = (a, b, sortBy) => {
  return a[sortBy] != undefined && b[sortBy] != undefined
    ? a[sortBy].toString().localeCompare(b[sortBy].toString())
    : a[sortBy] != undefined
    ? -1
    : b[sortBy] != undefined
    ? 1
    : 0
}
const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })
export const sortAlphaNum = (a, b, sortBy) => {
  return a[sortBy] != undefined && b[sortBy] != undefined
    ? collator.compare(a[sortBy], b[sortBy])
    : a[sortBy] != undefined
    ? -1
    : b[sortBy] != undefined
    ? -1
    : 0
}

export const sortNumber = (a, b, sortBy) => {
  return a[sortBy] != undefined && b[sortBy] != undefined
    ? a[sortBy] - b[sortBy]
    : a[sortBy] != undefined
    ? -1
    : b[sortBy] != undefined
    ? 1
    : 0
}

export const sortDate = (a, b, sortBy, format) => {
  let aValue = a[sortBy]
  let bValue = b[sortBy]
  if (format) {
    aValue = unformatDate(a[sortBy], format)
    bValue = unformatDate(b[sortBy], format)
  }

  return isValidTimeValue(aValue) && isValidTimeValue(bValue)
    ? bValue - aValue
    : 0
}

export const sortInvoiceDate = (a, b, sortBy) => {
  if (a[sortBy] != undefined && b[sortBy] != undefined) {
    if (sortBy.toLowerCase().includes('due_date')) {
      const [dayFirst, monthFirst, yearFirst] =
        b[sortBy].props.children[0].props.children.split('-')
      const [dayLast, monthLast, yearLast] =
        a[sortBy].props.children[0].props.children.split('-')
      const dateFirst = new Date(+yearFirst, monthFirst - 1, +dayFirst)
      const dateLast = new Date(+yearLast, monthLast - 1, +dayLast)
      return dateLast - dateFirst
    } else {
      const [dayFirst, monthFirst, yearFirst] = b[sortBy].split('-')
      const [dayLast, monthLast, yearLast] = a[sortBy].split('-')
      const dateFirst = new Date(+yearFirst, monthFirst - 1, +dayFirst)
      const dateLast = new Date(+yearLast, monthLast - 1, +dayLast)
      return dateLast - dateFirst
    }
  } else {
    return a[sortBy] != undefined ? -1 : b[sortBy] != undefined ? 1 : 0
  }
}

export const setItemsSort = (sortBy, setSortBy, sort, setSort, setItems) => {
  setSortBy(sortBy)
  switch (sort) {
    case 'asc':
      setSort('desc')
      setItems(prev =>
        prev.sort((a, b) => {
          if (sortBy.toLowerCase().includes('date')) {
            if (a[sortBy] != undefined && b[sortBy] != undefined) {
              const [dayFirst, monthFirst, yearFirst] = a[sortBy].split('-')
              const [dayLast, monthLast, yearLast] = b[sortBy].split('-')
              const dateFirst = new Date(+yearFirst, monthFirst - 1, +dayFirst)
              const dateLast = new Date(+yearLast, monthLast - 1, +dayLast)
              return dateLast - dateFirst
            } else {
              return a[sortBy] != undefined
                ? -1
                : b[sortBy] != undefined
                ? 1
                : 0
            }
          } else if (typeof a[sortBy] == 'number') {
            return a[sortBy] != undefined && b[sortBy] != undefined
              ? a[sortBy] - b[sortBy]
              : a[sortBy] != undefined
              ? -1
              : b[sortBy] != undefined
              ? 1
              : 0
          } else if (
            sortBy.toLowerCase().includes('supplier') ||
            sortBy.toLowerCase().includes('operation') ||
            sortBy.toLowerCase().includes('tenant') ||
            sortBy.toLowerCase().includes('landlord') ||
            sortBy.toLowerCase().includes('account') ||
            sortBy.toLowerCase().includes('list_task') ||
            sortBy.toLowerCase().includes('assigned_to')
          ) {
            return a[sortBy]?.display != undefined &&
              b[sortBy]?.display != undefined
              ? a[sortBy]?.display
                  .toString()
                  .localeCompare(b[sortBy].display.toString())
              : a[sortBy]?.display != undefined
              ? -1
              : b[sortBy]?.display != undefined
              ? 1
              : 0
          } else if (
            sortBy.toLowerCase().includes('floors') ||
            sortBy.toLowerCase().includes('fee_rules')
          ) {
            return a[sortBy][0]?.display != undefined &&
              b[sortBy][0]?.display != undefined
              ? a[sortBy][0]?.display
                  .toString()
                  .localeCompare(a[sortBy][0]?.display.toString())
              : a[sortBy][0]?.display != undefined
              ? -1
              : b[sortBy][0]?.display != undefined
              ? 1
              : 0
          } else if (sortBy.toLowerCase().includes('investor')) {
            return a[sortBy]?.name != undefined && b[sortBy]?.name != undefined
              ? a[sortBy]?.name
                  .toString()
                  .localeCompare(b[sortBy].name.toString())
              : a[sortBy]?.name != undefined
              ? -1
              : b[sortBy]?.name != undefined
              ? 1
              : 0
          } else {
            return a[sortBy] != undefined && b[sortBy] != undefined
              ? a[sortBy].toString().localeCompare(b[sortBy].toString())
              : a[sortBy] != undefined
              ? -1
              : b[sortBy] != undefined
              ? 1
              : 0
          }
        })
      )
      break

    case 'desc':
      setSort('asc')
      setItems(prev =>
        prev.sort((a, b) => {
          if (sortBy?.toLowerCase()?.includes('date')) {
            if (a[sortBy] != undefined && b[sortBy] != undefined) {
              const [dayFirst, monthFirst, yearFirst] = a[sortBy].split('-')
              const [dayLast, monthLast, yearLast] = b[sortBy].split('-')
              const dateFirst = new Date(+yearFirst, monthFirst - 1, +dayFirst)
              const dateLast = new Date(+yearLast, monthLast - 1, +dayLast)
              return dateFirst - dateLast
            } else {
              return a[sortBy] != undefined
                ? 1
                : b[sortBy] != undefined
                ? -1
                : 0
            }
          } else if (typeof a[sortBy] == 'number') {
            return a[sortBy] != undefined && b[sortBy] != undefined
              ? b[sortBy] - a[sortBy]
              : a[sortBy] != undefined
              ? 1
              : b[sortBy] != undefined
              ? -1
              : 0
          } else if (
            sortBy.toLowerCase().includes('supplier') ||
            sortBy.toLowerCase().includes('operation') ||
            sortBy.toLowerCase().includes('tenant') ||
            sortBy.toLowerCase().includes('list_task') ||
            sortBy.toLowerCase().includes('landlord') ||
            sortBy.toLowerCase().includes('account') ||
            sortBy.toLowerCase().includes('assigned_to')
          ) {
            return a[sortBy]?.display != undefined &&
              b[sortBy]?.display != undefined
              ? b[sortBy]?.display
                  .toString()
                  .localeCompare(a[sortBy].display.toString())
              : a[sortBy]?.display != undefined
              ? 1
              : b[sortBy]?.display != undefined
              ? -1
              : 0
          } else if (
            sortBy.toLowerCase().includes('floors') ||
            sortBy.toLowerCase().includes('fee_rules')
          ) {
            return a[sortBy][0]?.display != undefined &&
              b[sortBy][0]?.display != undefined
              ? b[sortBy][0]?.display
                  .toString()
                  .localeCompare(a[sortBy][0]?.display.toString())
              : a[sortBy][0]?.display != undefined
              ? 1
              : b[sortBy][0]?.display != undefined
              ? -1
              : 0
          } else if (sortBy.toLowerCase().includes('investor')) {
            return a[sortBy]?.name != undefined && b[sortBy]?.name != undefined
              ? b[sortBy]?.name
                  .toString()
                  .localeCompare(a[sortBy].name.toString())
              : a[sortBy]?.name != undefined
              ? 1
              : b[sortBy]?.name != undefined
              ? -1
              : 0
          } else {
            return a[sortBy] != undefined && b[sortBy] != undefined
              ? b[sortBy].toString().localeCompare(a[sortBy].toString())
              : a[sortBy] != undefined
              ? 1
              : b[sortBy] != undefined
              ? -1
              : 0
          }
        })
      )
      break
  }
}

export const apiSorting = ({ /*sorted = [],*/ field, direction }) => {
  const fieldToAdd = direction === 'desc' ? '-' + field : field
  // const sortedFiltered = sorted.filter(s => s != field && s != '-' + field)
  // return {
  //   sort_by: direction ? [...sortedFiltered, fieldToAdd] : sortedFiltered
  // }
  return { sort_by: direction ? fieldToAdd : '' }
}
